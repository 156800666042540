import React, { useContext, useState } from "react"
import Img from "gatsby-image"
import Hero from "~/components/configurable/Hero"
import FormInput from "../olc-framework/FormInput"
import BlockCTA from "../configurable/BlockCTA"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import FormTextarea from "../olc-framework/FormTextarea"
import Col3 from "../grid/Col3"
import Col6 from "../grid/Col6"
import Col9 from "../grid/Col9"
import Col8 from "../grid/Col8"
import Col4 from "../grid/Col4"

import ContactUsDetails from "~/vectors/contact-us-details.inline.svg"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { useStaticQuery, graphql } from "gatsby"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import FormSelect from "../olc-framework/FormSelect"

import "./contact-us-new.scss"
import Icon from "../olc-framework/Icon"

const CommercialContactUsPage = ({ location }) => {
  const { state = {} } = location

  

  const logFormSubmitEvent = () => {
    if (window) {
      window.dataLayer = window.dataLayer || []
    }

    const eventData = {
      category: "Form",
      action: "Submit",
      label: "Contact Us",
      // value: 0 // optional
    }

    trackCustomEvent(eventData)
  }

  //this makes it so the customer type is set always as what it needs to be on that page

  const { customerType, setCustomerType } = useContext(CustomerTypeContext);

  const isBusiness = React.useMemo(() => customerType === "commercial", [customerType]);
  const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);
  const isSolarTogether = React.useMemo(() => customerType === "solartogether", [customerType]);
  
    React.useEffect(() => {
      setCustomerType('commercial');
    }, []);

//END this makes it so the customer type is set always as what it needs to be on that page

  
  // START this sets the department

  const [department, setDepartment] = useState('');

  const domesticDep = React.useMemo(() => department === "Domestic Sales Enquiries", [department]);
  const commercialDep = React.useMemo(() => department === "Commercial Sales Enquiries", [department]);
  const supportDep = React.useMemo(() => department === "Customer Services", [department]);

  const departmentContext = React.useMemo(() => {
    const context: any = {};
    if (domesticDep) {
      context.URL =  'https://formspree.io/f/xdoyblrb';
      context.fourthField = 'Reference number (if applicable)';
      context.fourthTip = 'Type your SPUK number';
    } else if (commercialDep) {
      context.URL =  'https://formspree.io/f/xayavdgk';
      context.fourthField = 'Company';
      context.fourthTip = 'Type your company name';
    } else if (supportDep) {
      context.URL =  'https://formspree.io/f/xwkrpynw';
      context.fourthField = 'Reference number';
      context.fourthTip = 'Type your SPUK number';
    }
    return context;
  }, [domesticDep, commercialDep, supportDep]);

  // ENDS this sets the department


  return (
    <div className="contact-us-page">
      <Hero
        imageUrl="/images/contact-banner.jpg"
        // image={<Img fluid={heroImage.fluid} alt="Solar panel roof" />}
        compact
      >
        <Heading level={1} underlined>
          Contact
        </Heading>
      </Hero>
      <Block>
        <div className="container u-layout--indent container--column">
          <div className="row">
            <Col8>
              <form
                className="form form--full-width"
                onSubmit={() => {
                  logFormSubmitEvent()
                }}
                action={departmentContext.URL}
                method="POST"
                name="contact-us"
              >
                <div className="row">
                  <Col6 className="formSelect">
                      <FormSelect
                        name="department"
                        label="Department"
                        options={["Domestic Sales Enquiries", "Commercial Sales Enquiries", "Customer Services"]}
                        onChange={e => setDepartment(e.target.value)}
                      />
                  </Col6>
                  <Col6>
                    <FormInput
                      name={departmentContext.fourthField}
                      label={departmentContext.fourthField || 'Company'}
                      placeholder={departmentContext.fourthTip || 'Type your company name'}
                    />
                  </Col6>
                </div>
                <div className="row">
                  <Col6>
                    <FormInput
                      name="full-name"
                      label="Full name"
                      placeholder="Type your full name"
                      value={state?.name}
                      required
                    />
                  </Col6>
                  <Col6>
                    <FormInput
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="Type your email"
                      value={state?.email}
                      required
                    />
                  </Col6>
                </div>
                <div className="row">
                  <Col6>
                    <FormInput
                      name="phone-number"
                      label="Phone number (optional)"
                      type="tel"
                      placeholder="Type your phone number"
                      value={state?.phone}
                    />
                  </Col6>
                  <Col6>
                    <FormInput
                      name="subject"
                      label="Subject"
                      placeholder="Type the subject"
                    />
                  </Col6>
                </div>

                <FormTextarea
                  name="message"
                  label="Message"
                  placeholder="Type your message"
                  required
                />
                <div className="form__actions">
                  <BlockCTA fullWidth large submit>
                    Send
                  </BlockCTA>
                </div>
              </form>
            </Col8>
            <Col4>
                <div className="numbersBlock " >
                                    
                <div className="numbersBlockFirstItem">
                    <img src={require('../../images/com.png')} style={{margin:'auto'}}/>
                    <div className="numbersBlockItemTitle">
                    <strong >
                      Commercial enquiries
                    </strong>
                    </div>
                    <div>
                      <a
                        href="tel:01920452175"
                      >
                        <Icon alias="phone" />
                        ⠀01920 452 175
                      </a>
                    </div> 
                    <div>
                      <a
                        href="mailto:commercial.enquiries@get-uk.com"
                        
                      >
                        <Icon alias="email" />
                        <span style={{
                          
                        }}> commercial.enquiries@get-uk.com </span>
                        ⠀
                      </a>
                    </div> 
                  </div> 
                  
                  
                  <div className="numbersBlockItem " >
                    <img src={require('../../images/dom.png')} style={{margin:'auto'}}/>
                    <div className="numbersBlockItemTitle">
                    <strong >
                      Domestic customers
                    </strong><br/>
                    </div>
                    <div>
                      <a
                        href="tel:02039954422"
                        style={{

                        }}
                      >
                        <Icon alias="phone" />
                        ⠀020 3995 4422
                      </a>
                    </div> 
                    <div>
                      <a
                        href="mailto:domestic.enquiries@get-uk.com"
                        style={{

                        }}
                      >
                        <Icon alias="email" />
                        ⠀support@get-uk.com
                      </a>
                    </div> 
                  </div>

                  
                  <div className="numbersBlockItem">
                  <img src={require('../../images/st.png')} style={{margin:'auto'}}/>
                  <div className="numbersBlockItemTitle">
                  <strong>
                    Solar Together
                  </strong>
                  </div>
                  <div>
                    <a
                      href="tel:02039898987"
                      style={{

                      }}
                    >
                      <Icon alias="phone" />
                      ⠀London: 020 3989 8987
                    </a>
                  </div> 
                  <div>
                    <a
                      href="tel:02038669896"
                      style={{

                      }}
                    >
                      <Icon alias="phone" />
                      ⠀Devon: 020 3866 9896
                    </a>
                  </div> 
                  <div>
                    <a
                      href="mailto:solar.together@get-uk.com"
                      style={{

                      }}
                    >
                      <Icon alias="email" />
                      ⠀solar.together@get-uk.com
                    </a>
                  </div> 
                </div>
                </div>
                <div className="addressBlock">
                  <strong>
                    Where to find us
                  </strong>
                  <p className="companyAddress">
                  8 Peerglow Center, <br/>
                  Marsh Lane <br/>
                  Ware, <br/>
                  Hertfordshire, <br/>
                  SG12 9QL<br/>
                  Registration: 09750103<br/>
                  </p>
                </div>                      
            </Col4>
          </div>
        </div>
      </Block>
    </div>
  )
}

export default CommercialContactUsPage
